import React from 'react'

import { Image, Button } from 'react-bootstrap'
import moment from 'moment'

import ActiveStatus from '../../components/ActiveStatus'
import { CHALLENGE_END_DATE, CHALLENGE_START_DATE, CHALLENGE_NAME, CHALLENGE_EARNED } from '../../constants';

import Styles from './HomeScreen.module.scss'

const HomeScreen = () => {

  const signHandler = () => {
    window.location.href = `https://beyondthewhiteboard.com/earned/challenges/${CHALLENGE_EARNED}`
  }

  const isClosed = () => {
    return moment(CHALLENGE_END_DATE + '-04:00').isBefore(moment());
  }

  return (
    <>
      <div className={Styles.poster}></div>

      <div className={Styles.container}>
        <div className={Styles.right}>
          <div className={Styles.content}>
            <h2>{CHALLENGE_NAME}</h2>
            <h3>Challenge details</h3>
            <p>{moment(CHALLENGE_START_DATE).format('MMMM DD [at] ha [EDT]')} - {moment(CHALLENGE_END_DATE).format('MMMM DD [at] ha [EDT]')}</p>
            <ActiveStatus />

            {/* <SmallLogo topTitle='Charity' className={Styles.charity} imageName="charity" />
            <p>Serving honor and need.</p> */}
            {!isClosed() &&
               <div className={Styles.action}>
                <Button
                  variant='primary'
                  className={Styles.btn}
                  onClick={signHandler}
                >
                  Sign up
                </Button>
              </div>
            }

            <div className={Styles.textBox}>
              <h3>Price</h3>
              <p className={Styles.light}>
                Registration: <span>Free</span>
              </p>
              <p className={Styles.light}>
                With Bottle: <span>$30</span>
              </p>
              {/* <p className={Styles.light}>
              With Sticker Pack: <span>$16</span>
              </p> */}
            </div>

            <div className={Styles.textBox}>
              <h3>Workout description</h3>
              <p>For time:</p>
              <p>400 m Run</p>
              <p>20 Burpees</p>
              <p>400 m Run</p>
              <p>19 Burpees</p>
              <p>400 m Run</p>
              <p>18 Burpees</p>
              <p>400 m Run</p>
              <p>17 Burpees</p>
              <p>400 m Run</p>
              <p>16 Burpees</p>
              <p>400 m Run</p>
              <p>15 Burpees</p>
              <p>etc…</p>
                <br />
              <p>…until</p>
              <p>400 m Run</p>
              <p>1 Burpee</p>
              <br />
              <p>Men: 20-lb weight vest</p>
              <p>Women: 14-lb weight vest</p>
            </div>

            <div className={[Styles.textBox, Styles.division].join(' ')}>
              <h3>Divisions</h3>
              <p>Weighted (All ages)</p>
              <p>Unweighted (All ages)</p>
              <p>Air Bike (All ages)</p>
              <p>Row (All ages)</p>
            </div>
            
            <div className={Styles.pdfs}>
              <p><a target="_blank" href="https://s3.amazonaws.com/media.btwb.co/Competitions/linchpin/2024/linchpin_test_13/rules.pdf" rel="noreferrer">Rules Guide PDF</a></p>
              {/*<p><a href="https://s3.amazonaws.com/media.btwb.co/Earned+Series/dt_lessonplan.pdf">Gym Lesson Plan PDF</a></p>*/}
            </div>

            <div className={[Styles.textBox, Styles.badge].join(' ')}>
              <h3>{CHALLENGE_NAME.replace('Challenge', '')} BADGE</h3>
              <div className={Styles.icon}></div>
              <p>This badge proves you do what's effective, not what's popular.</p>
            </div>

            <div className={[Styles.textBox, Styles.contact].join(' ')}>
              <h3>Contact</h3>
              <a href='mailto:support@btwb.com'>support@btwb.com</a>
            </div>
          </div>
        </div>

        <div className={Styles.left}>
          <div className={Styles.content}>
            <p>Welcome to the "{CHALLENGE_NAME}" presented by CrossFit Linchpin. This is a you versus you challenge to test your fitness, earn cool gear, and have fun doing it. Fitness is a journey with many milestones along the way worth celebrating. At Linchpin we do what's effective, not what's popular.
            </p>
            <p>This challenge is <b>exclusive to Linchpin subscribers</b>. If you're not part of a Linchpin program on btwb, you can sign-up using the button below. All users start with a <b>free 30-day trial!</b></p>

            <p>
              <a href="https://programs.btwb.com/linchpin/individual" target="_blank" noopener rel="noreferrer">
                <Button className={Styles.sign} variant='primary'>JOIN LINCHPIN</Button>
              </a>
            </p>

            <div className={Styles.howBlock}>
              <h3>How it works</h3>
              <ul>
                <li>Register using the Sign Up button</li>
                <li>Review the rules</li>
                <li>Do the workout!</li>
                <li>Log your score to btwb</li>
                <li>Receive your gear & in-app challenge badge</li>
              </ul>
              <p>
                Scores and gear orders must be submitted no later than {moment(CHALLENGE_END_DATE).format('dddd Do [of] MMMM [@] h[:]mma [EDT]')}. Be sure to allow sufficient time to register, log, and make your purchase.
              </p>
            </div>
            {/*<div className={Styles.howBlock}>
              How it works
              <ul>
                <li>Register using the Sign Up button</li>
                <li>Review the rules and video submission guidelines</li>
                <li>Do the workout!</li>
                <li>Log your score to btwb & attach a video</li>
                <li>Await a judging team member's ruling</li>
                <li>Receive your t-shirt & challenge badge</li>
              </ul>
            </div>*/}
            <div className={Styles.video}>
              {/*<button className={Styles.play}></button>*/}
              <Image src='https://s3.amazonaws.com/media.btwb.co/Competitions/linchpin/2024/linchpin_test_13/lp_bottle.png' fluid className={Styles.big} />
            </div>
            <div className='mt-5'>
            </div>
            {/* <div className={Styles.video}>
              <Image src='https://s3.amazonaws.com/media.btwb.co/Competitions/linchpin/Linchpin-test-14/lp_tank.png' fluid className={Styles.big} />
            </div> */}
            <h2>Have Fun & Earn It!</h2>
            <p>This challenge is all about you vs you. Yes, other people will be doing the same workout as you, but at the end of the day what matters is YOUR effort. The entire purpose of this challenge is to push yourself while being part of a supportive global community. Do the work, earn the gear & get to see your name on the back of the shirt! Videos are not required because this is challenge is for fun & camaraderie. Next year we will do it again & you can compare your scores.</p>
            {/* <div className={Styles.howBlock}>
              Earned T-shirts and challenge badges will have 4 different levels:
              <ul>
                <li>Level 1: Earned when the scaled version of the workout is completed</li>
                <li>Level 2: Earned when the challenge workout is performed as prescribed, regardless of time.</li>
                <li>Level 3: Earned when the challenge workout is performed in 12:00 or less. </li>
                <li>Level 4: Earned when the challenge workout is performed in 7:00 or less. These scores are in the top 10% of btwb times.</li>
              </ul>
              <p>In this challenge you truly earn your t-shirt and badge. It's actual proof you worked for your result, and you should feel proud.</p>
            </div>
            <h2>Give Back To Your Gym</h2>
            <p>Some of the best places are independently owned gyms. We love their unique character and cultures. These are places where we set personal records and escape to when we need to de-stress. Btwb's mission has always been to make technology for these communities and we are excited to say we are taking another big step.
              <b> Part of of the proceeds from each earned shirt will go back to your gym. </b> 
              Gyms interested in participating can get <a href="https://btwb.blog/2021/07/01/fitness-communities-discover-new-revenue-streams/">more information here</a>.  
            </p> */}
            {/*<div className={Styles.video}>
              <button className={Styles.play}></button>
              <Image src='./images/video_bg.png' fluid className={Styles.big} />
            </div>*/}
            <div className={Styles.cellar}>
              {/*<SmallLogo topTitle='Charity' className={Styles.charity} imageName="charity" />
              <p>For every shirt purchased a tree will be planted.</p>*/}

              <div className={[Styles.textBox, Styles.badge].join(' ')}>
                <h3>{CHALLENGE_NAME.replace('Challenge', '')} BADGE</h3>
                <div className={Styles.icon}></div>
                <p>This badge proves you do what's effective, not what's popular.</p>
              </div>

              <div className={[Styles.textBox, Styles.contact].join(' ')}>
                <h3>Contact</h3>
                <a href='mailto:support@btwb.com'>support@btwb.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeScreen
