import React, { useState } from 'react'

import { Container, Row, Col, Button } from 'react-bootstrap'
// import { Link } from 'react-router-dom';
import { CHALLENGE_START_DATE, CHALLENGE_END_DATE, CHALLENGE_NAME, CHALLENGE_DIRECTORY } from '../../constants';
import moment from 'moment';

import Questions from './Questions'

import Styles from './FaqScreen.module.scss'

const initialQuestions = [
  {
    sectionTitle: 'General',
    id: 'q1',
    open: true,
    questions: [
      {
        title: 'When does the challenge start/end?',
        answer: `${CHALLENGE_NAME} starts ${moment(CHALLENGE_START_DATE).format('MMMM DD, YYYY [at] ha [EDT]')} and ends ${moment(CHALLENGE_END_DATE).format('MMMM DD, YYYY [at] ha [EDT]')}. Registration will remain open through the close of the challenge on ${moment(CHALLENGE_END_DATE).format('MMMM Do')}. All scores must be submitted by ${moment(CHALLENGE_END_DATE).format('ha [EDT] [on] MMMM D, YYYY')} in order to be eligible for leaderboard placing, challenge badge, and earned t-shirt (if purchased).`,
      },
      {
        title: 'How do I register?',
        answer: `Use the “Sign-up” button on the Challenge home page <a href="https://challenges.btwb.com/${CHALLENGE_DIRECTORY}">here</a>. Registration will remain open through ${moment(CHALLENGE_END_DATE).format('MMMM DD, YYYY [at] ha [EDT]')}. A btwb account and active Linchpin subscription is required for participation. Participants may log into an existing btwb account or create a brand new account. All new accounts will come with a 30-day free btwb trial.`
      },
      {
        title: 'Do I need to have a btwb subscription to participate?',
        answer:
          `Participants will need to have a btwb subscription in order to participate in ${CHALLENGE_NAME}. Existing btwb users with paying individual subscriptions, or subscriptions through their gym, are fine as is. Participants who create brand new btwb accounts during the registration process will be granted a free 30-day btwb trial subscription. The trial subscription will be good for 30 days from the point of registration. New btwb users will be required to set up an individual paying subscription once the free trial expires if they wish to continue to use btwb.`,
      },
      {
        title: 'Do I need to be part of a gym on btwb to participate?',
        answer:
          'btwb accounts do NOT have to be associated with a specific gym on btwb.',
      },
      {
        title: 'Can I switch divisions after registering?',
        answer: `Participants will be able to switch divisions after the registration process is completed. They will be able to make the switch from the "${CHALLENGE_NAME}" screen located in the btwb app.`,
      },
    ]
  },/* 
  {
    sectionTitle: 'Earned Shirts',
    id: 'q2',
    open: false,
    questions: [
      {
        title: 'Which T-shirt will I earn?',
        answer:
          'Everyone gets the same t-shirt or tank top. Your first name & the first letter of your last name will be displayed on the back of the shirt along with everyone else who participates.',

      },
      {
        title: 'What kind of T-shirt is it?',
        answer:
          'Shirt options include a Unisex T-Shirt and Women\'s Racerback Tank in various sizes. See the respective <a href="https://s3.amazonaws.com/media.btwb.co/Competitions/linchpin/5K+Run/shirt_sizeguide.png">T-Shirt Size Guide</a> and <a href="https://s3.amazonaws.com/media.btwb.co/Competitions/linchpin/5K+Run/tank_sizeguide.png">Tank Top Size Guide</a> for measurements.',

      },
      {
        title: 'When will I receive my shirt?',
        answer:
          'It can take up to 5 weeks for the print shop to create your unique shirt. Once your shirt has been created, it will take an average shipping time of 4 business days depending on the delivery destination. BTWB is not responsible for lost or stolen shipments.',

      },
      {
        title: 'How does international shipping work?',
        answer:
          'International shipping is available. It can take up to 5 weeks for the print shop to create your unique shirt. Once your shirt has been created it will ship in accordance with the standard default shipping method offered by our fulfillment provider. Please keep in mind that many countries have their own unique requirements and/or fees for packages. BTWB is not responsible for any additional fees, requirements, documents, etc. that may be required. It is your responsibility to ensure all requirements are met. BTWB is not responsible for lost or stolen shipments.',

      },
      {
        title: 'Can I buy a shirt if I didn’t order one during registration?',
        answer:
          'Yes, but you\'ll need to email <a href="mailto:support@btwb.com">support@btwb.com</a>. We\'ll provide instructions for purchasing a Linchpin Challenge Shirt.',

      },
      {
        title: 'How do I return/exchange my shirt?',
        answer:
          'If your shirt does not fit or has a defect that is our fault, you may exchange the item. Please send an email to support@btwb.com. In the subject line state the name of the challenge and “t-shirt exchange”. Please include your order number in the email as well as your complete name and mailing address. In the email please tell us the problem and if you wish to exchange the shirt. If you need a different size, be sure to include the size you wish to receive. If your shirt arrived with a material defect, please state what the defect was and include a photo of the defect. It could take up to 4-6 weeks to receive a new shirt.',
      },
    ]
  }, */
  {
    sectionTitle: 'btwb',
    id: 'q3',
    open: false,
    questions: [
      {
        title: 'How do I log a result for the Challenge workout?',
        answer: `Participants will log scores to the workout from the ${CHALLENGE_NAME} screen in the btwb app. Click on the “Log” button, then follow each of the steps in the process.`,
      },
      {
        title: 'Can I log multiple scores?',
        answer:
          'Yes. If participants perform the workout multiple times, they can log each score from the Linchpin 5K Run Challenge screen in the btwb app. The challenge will automatically replace any previously logged score with a better logged score, so participants are encouraged to log as many results as they want.',

      },
      {
        title: 'Can I edit my result?',
        answer: `Yes. Participants can edit their logged score any time before ${moment(CHALLENGE_END_DATE).format('dddd[,] MMMM DD[,] YYYY [at] ha [EDT]')}.`,

      },
    ]
  },
  // {
  //   sectionTitle: 'Videos',
  //   id: 'q4',
  //   open: false,
  //   questions: [
  //     {
  //       title: 'Do I need a video?',
  //       answer:
  //         'Yes! It\'s what separates an Earned challenge from other challenges out there. Everyone needs a video for Earned challenges if they want to appear on the leaderboard for their division, receive an earned shirt (if paid for), and receive an exclusive challenge badge. Failure to attach a video will exclude participants from each of those items.',

  //     },
  //     {
  //       title: 'What are the specific video guidelines?',
  //       answer: 'The barbell & plates should be filmed in a way that allows the loads to be clearly identified. Videos need to be uncut & unedited in order to accurately display the performance. Videos shot with a fisheye lens or similar lens may be rejected, we recommend not using such lenses. The clock or timer must be visible during the entire workout. Phone timers, apps & stopwatches are acceptable as long as they are in frame and clearly visible the entire time. Ensure the athlete has ample space to safely perform all aspects of the workout & all potential obstructions have been removed. The video should be filmed in such a way as to clearly capture the entire range of motion of all movements required for the workout and allow for easy verification of the movement standards.<br/><br/>At the start of the video the athlete should be in frame, state their name, the challenge they are performing and which workout variation they choose to do (Rx’d, Scaled, etc.). Next, the barbell & plates should be filmed in a way that allows the loads to be clearly identified. Keep in mind everything needs to be filmed in one continuous, unedited video.<br/><br/>Videos need to be uploaded and attached to your btwb result no later than Tuesday, December 21st, 2021 at 11:59 PST. Be sure to allow sufficient time to submit your score and attach your video.<br/><br/>You can view video submission guidelines <a href="https://youtu.be/QfF1Y-TxP54">here</a>.',

  //     },
  //     {
  //       title: 'What if my video does NOT meet the requirements?',
  //       answer:
  //         'In this case your videos would not be able to be validated and therefore you would not be able to earn a placement on the leaderboard.',

  //     },
  //     {
  //       title: 'What visibility settings does my video need?',
  //       answer:
  //         'Videos uploaded to Youtube or Vimeo need to be made “public” so that the btwb judging team can review the performance. Our team gets a lot of videos set as “private”. In those cases, because our judging team cannot review the performance, submissions are rejected. Please make sure our judging team is able to review your videos.',

  //     },
  //   ]
  // },
  // {
  //   sectionTitle: 'Judging',
  //   id: 'q5',
  //   open: false,
  //   questions: [
  //     {
  //       title: 'Do I need a judge when I do the workout?',
  //       answer:
  //         'You won’t need a judge while performing the actual workouts. Btwb has its own team of judges that will be reviewing and validating each video after the challenge has officially closed. We recommend, however, having somebody to ensure you meet the standards required.',

  //     },
  //     {
  //       title: 'How will the videos be verified?',
  //       answer:
  //         'All participants who submitted a video will receive an email notification from the judging team once their performance has been reviewed. If your performance is assessed a penalty, the details of the penalty will be made available in that email within two weeks of the end of the Challenge.',

  //     },
  //     {
  //       title: 'Will penalties be assessed?',
  //       answer:
  //         'Yes, if necessary penalties will be assessed on a case by case basis as determined during the video review process.',

  //     },
  //     {
  //       title: 'How does this affect leaderboard placing?',
  //       answer:
  //         'Any penalties assessed may result in an adjustment to the athlete’s score. This adjustment in score may result in the athlete’s position on the leaderboard shifting.Athletes are only eligible for the leaderboard placement and potential prizes by their score after any penalties have been assessed if necessary.',

  //     },
  //     {
  //       title: 'When/how will I know if any penalties have been assessed?',
  //       answer:
  //         'If your performance is assessed a penalty you will be informed by email within two weeks of the end of the Challenge.',

  //     },
  //     {
  //       title: 'Can I challenge any assessed penalties?',
  //       answer:
  //         'No. All assessed penalties will be final.',

  //     },
  //     {
  //       title: 'When can I expect my score to be validated?',
  //       answer:
  //         'Scores should be validated within 10 days of the end of the Challenge.',

  //     },
  //   ]
  // },
  // {
  //   sectionTitle: 'Charity',
  //   id: 'q6',
  //   open: false,
  //   questions: [
  //     {
  //       title: 'Forging Youth Resilience',
  //       answer:
  //         'is a member-based, nonprofit organization that partners with fitness facilities nationwide to bring fitness, nutrition, and mentorship to youth who otherwise would not have access to these services. FYR uses fitness to teach imperative life skills like perseverance, grit, determination, resilience, and confidence through teamwork, leadership, and goal setting to prepare youth athletes to face the challenges life throws their way with grace, maturity, and strength.',

  //     },
  //   ]
  // },
]

const FaqScreen = () => {
  const [questions, setQuestions] = useState(initialQuestions)

  const toggleQuestion = (id) => {
    setQuestions((prev) => {
      const idx = prev.findIndex((item) => item.id === id)
      const updatedQuestion = { ...prev.find((item) => item.id === id) }
      updatedQuestion.open = !updatedQuestion.open
      return [...prev.slice(0, idx), updatedQuestion, ...prev.slice(idx + 1)]
    })
  }

  return (
    <Container>
      <Row>
        <Col className={Styles.faq}>
          <h2>Frequently asked questions</h2>
          <Questions questions={questions} onToggle={toggleQuestion} />
          <div className={Styles.contact}>
            <h2>Do you still have questions?</h2>
            <h3>Feel free to reach out with any questions you might have. We're here to help. </h3>
            <a href={'mailto:support@btwb.com'}>
              <Button variant='primary' className={Styles.btn}>
                Contact us
              </Button>
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default FaqScreen
