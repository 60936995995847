import React, { useState, useEffect } from 'react';


import { Container, Row } from 'react-bootstrap'
import LeaderboardModel from '../../models/Leaderboards';
import ActiveStatus from '../../components/ActiveStatus';
import Leaderboard from '../../components/Leaderboard';

import Styles from './LeaderboardScreen.module.scss';
import { CHALLENGE_NAME } from '../../constants';

const LeaderboardScreen = () => {
  let timeoutHack = undefined;

  const [gender, setGender] = useState('male');
  const [maleDropdown, setMaleDropdown] = useState([]);
  const [femaleDropdown, setFemaleDropdown] = useState([]);
  const [activeDivision, setActiveDivision] = useState();
  const [search, setSearch] = useState('');
  const [tempSearch, setTempSearch] = useState('');
  const [page, setPage] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(0);

  const [standings, setStandings] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const asyncLeaderboardsFetch = async () => {
      const {data: leaderboards} = await LeaderboardModel.where({displayName: 'Linchpin 5K Run Challenge', season: "2024"}).all();
      const male = leaderboards.filter(lb => {
        if(lb.gender.toLowerCase() === 'male') return true;
        return false;
      });

      console.log(leaderboards)
      const female = leaderboards.filter(lb => {
        if(lb.gender.toLowerCase() === 'female') return true;
        return false;
      }).sort((a,b) => a.position > b.position ? 1 : -1);
      setMaleDropdown(male);
      setFemaleDropdown(female);
      setActiveDivision(480); //just default val
    }
    asyncLeaderboardsFetch(); 

  }, []);

  const getStandings = async (queryParams) => {
    if(!activeDivision) return null;
    const activeLeaderboardPromise = await fetch(`https://phelan.btwb.com/leaderboards/${activeDivision}?${queryParams}`);
    const activeLeaderboard = await activeLeaderboardPromise.json();
    setStandings(activeLeaderboard.standings);
    setEvents(activeLeaderboard.events);
  }

  useEffect(() => {
    const asyncLeaderboard = async () => {
      if(!activeDivision) return null;
      const offsetString = (page !== 0 && !search) ? `offset=${20 * page}` : '';
      const searchString = search ? `&name=${search}` : '';
      const eventString = selectedEvent ? `&event=${selectedEvent}` : '';

      await getStandings(offsetString + searchString + eventString);
    };
    asyncLeaderboard();
  }, [activeDivision, page, search, selectedEvent]);

  useEffect(() => {
    if(page !== 0) {
      setPage(0)
    }
  }, [activeDivision])

  useEffect(() => {

    if(!gender) return null;
    if(page !== 0) setPage(0);

    if(gender === 'male') {
      return setActiveDivision(maleDropdown[0]?.id);
    }

    return setActiveDivision(femaleDropdown[0]?.id);
  }, [gender]);

  const debounceSearch = (searchVal) => {
    clearTimeout(timeoutHack);
    setTempSearch(searchVal);
    timeoutHack = setTimeout(() => {
      setSearch(searchVal);
    }, 300);
  }

  return (
    <Container className={Styles.leaderboard}>
      <Row className={Styles.header}>
        <h1 className={Styles.title}>{CHALLENGE_NAME} Leaderboard</h1>
        <ActiveStatus />
      </Row>
      <Row className={Styles.inputs}>
        <div className={Styles.dropdownsWrapper}>
          
          <div className={Styles.dropdownWrapper}>
            <label>Gender</label>
            <div className={Styles.selectChevron}>
              <select
                value={gender}
                onChange={e => setGender(e.target.value)}>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>

          {/* <div className={Styles.dropdownWrapper}>
            <label>Division</label>
            <div className={Styles.selectChevron}>
              <select 
                onChange={e => setActiveDivision(e.target.value)}
                value={activeDivision}>
                {(gender === 'male' ? maleDropdown : femaleDropdown).map((lb, idx) =>
                  <option 
                    key={idx}
                    value={lb.id}>
                    {lb.ageGroup}
                  </option>
                )}
              </select>
            </div>
          </div> */}
        </div>

        <div className={Styles.eventWrapper}>

          <div className={Styles.selectChevron}>
            <select onChange={e => setSelectedEvent(e.target.value)}>
              <option value={0}>All</option>
              {(events || []).map((e, i) =>
                <option 
                  value={e.id}
                  key={i}>
                  {e.display_name}
                </option>
              )}
            </select>
          </div>
        </div>

        <div className={Styles.inputWrapper}>
          <input
            placeholder="Search by name..."
            value={tempSearch}
            onChange={e => debounceSearch(e.target.value)}
          />
        </div>

      </Row>

      <div>
        {standings.length < 1 &&
          <h1 style={{textAlign: 'center'}}>Coming soon!</h1>
        }
        {standings.length > 0 &&
          <Leaderboard
          standings={standings}
          events={events}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          page={page}
          setPage={setPage}
          />
        }
      </div>
    </Container>
  )
};

export default LeaderboardScreen;