import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Logo from '../../components/Logo'
import Input from '../../components/UI/Input'
import SignContainer from '../../components/UI/SignContainer'

import { login } from '../../actions/userAction'

import Styles from './SignIn.module.scss'

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email required'),
  password: Yup.string()
    .required('Password required.')
    .min(6, 'Min length 6 characters.'),
})

const LoginPage = () => {
  let history = useHistory()

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      history.push('/success')
    }
  }, [history, userInfo])

  const onSubmitHandler = (values, { setSubmitting }) => {
    setTimeout(() => {
      console.log(values)
      const { email, password } = values

      dispatch(login(email, password))

      setSubmitting(false)
    }, 400)
  }

  const createHandler = () => {
    history.push('/register')
  }

  const getErrorMessage = (touched, errors) => {
    if (touched.email && errors.email) {
      return errors.email
    } else if (touched.password && errors.password) {
      return errors.password
    } else {
      return ''
    }
  }

  return (
    <div className={Styles.auth}>
      <div className={Styles.gradient}>
        <Link to='/'>
          <Logo isBtwb />
        </Link>
      </div>
      <div className={Styles.sign}>
        <div className={Styles['small-logo']}>
          <Link to='/'>
            <Logo isBtwb />
          </Link>
        </div>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <SignContainer className={Styles.box}>
            <h2>Sign In to your Account</h2>
            <p className={Styles.tip}>
              You'll need a btwb account to participate in this challenge.
            </p>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={SignupSchema}
              onSubmit={onSubmitHandler}
              validateOnBlur
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isValid,
                handleSubmit,
                dirty,
              }) => (
                <form onSubmit={handleSubmit} className={Styles.form}>
                  <div className='form-group'>
                    <Input
                      label='email'
                      name='email'
                      type='email'
                      placeholder='Enter your email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </div>

                  <div className='form-group'>
                    <Input
                      label='password'
                      name='password'
                      type='password'
                      placeholder='Enter your password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                  </div>

                  <div className={Styles.subContent}>
                    <div className={Styles.valueErrors}>
                      {getErrorMessage(touched, errors)}
                    </div>
                    <Link to={'/'} className={Styles.link}>
                      Forgot your password?
                    </Link>
                  </div>

                  <div className={Styles.action}>
                    <Button
                      className={Styles.btn}
                      variant='primary'
                      type='submit'
                      disabled={!isValid && !dirty}
                      block
                    >
                      Login
                    </Button>

                    <Button
                      className={Styles.btn}
                      variant='outline-primary'
                      block
                      onClick={createHandler}
                    >
                      Create account
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </SignContainer>
        )}
      </div>
    </div>
  )
}

const SignIn = () => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <LoginPage />,
        document.getElementById('login-page')
      )}
    </React.Fragment>
  )
}

export default SignIn
