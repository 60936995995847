import React from 'react';
import moment from 'moment';

import Styles from './ActiveStatus.module.scss';

import { CHALLENGE_START_DATE, CHALLENGE_END_DATE } from '../../constants';

const finalStart = moment(CHALLENGE_START_DATE + '-04:00');
const finalEnd = moment(CHALLENGE_END_DATE + '-04:00');

const ActiveStatus = () => {

  const statusFunction = () => {
     if( finalEnd.isBefore(moment()) ) {
      return {
        text: 'Competition is Closed',
        style: Styles.closed
      }
    } else if(finalStart.isAfter(moment())) {
      return {
        text: 'Competition is Upcoming',
        style: Styles.upcoming
      }
    } else if( finalEnd.isAfter(moment()) ) {
      return {
        text: 'Competition is Active!',
        style: Styles.open
      }
    } else {
      return {
        text: 'Competition is Active!',
        style: Styles.open
      }
    }
  }

  const status = statusFunction();

  return (
    <div className={Styles.activeStatus}>
      <p className={Styles.soon + ' ' + status?.style}>{status?.text}</p>
    </div>
  )
};

export default ActiveStatus;