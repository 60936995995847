import React from 'react'

import { Row, Col } from 'react-bootstrap'

import Card from '../../components/UI/Card'

import Styles from './Workout.module.scss'

const Workout = ({ workout }) => {
  const { 
    title, 
    description, 
    instructions,
    workoutRows,
    equipment, 
    pdfLinks, 
    videoSubmissionGuidelines,
    movementStandards,
    movementGifs,
    movementVideo
  } = workout
  return (
    <div className={Styles.workout}>
      <div className={Styles.title}>{title}</div>
      <Card className={Styles.card}>
        <Row>
          <Col md={3}>
            <h3>workout</h3>
            {(workoutRows || []).map((r, idx) =>
              <p key={idx}>{r}</p>
            )}
          </Col>
          <Col md={3} className={Styles.smhidden}>
          </Col>
          <Col md={6} className={Styles.reps}>
            <h3>score type</h3>
            <p>Total Time</p>
          </Col>
          <Col md={6} className={Styles.description}>
            <h3>description</h3>
            <p>{description}</p>
          </Col>
          {equipment && (
            <Col md={6} className={Styles.instructions}>
              <h3>Equipment</h3>
              <ul>
              {equipment.map((e, i) =>
                <li key={i}>{e}</li>
              )}
              </ul>
            </Col>
          )}
          {instructions && (
            <Col md={6} className={Styles.instructions}>
              <h3>instructions</h3>
              <p>{instructions}</p>
            </Col>
          )}
          {pdfLinks && (
            <Col md={6} className={Styles.instructions}>
              <h3>Downloads</h3>
              {pdfLinks.map((p, i) =>
                <a key={i} href={p.link} target="_blank" rel="noopener noreferrer">{p.title}</a>
              )}
            </Col>
          )}
          {videoSubmissionGuidelines && (
            <Col md={6} className={Styles.instructions}>
              <h3>Video Submission Guidlines</h3>
              <div className={Styles.youtube}>
                <div>
                  <iframe width="1225" height="689" src={`https://www.youtube.com/embed/${videoSubmissionGuidelines}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Card>
    </div>
  )
}

export default Workout
