import React from 'react'
import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import Logo from '../../components/Logo'
import SignContainer from '../../components/UI/SignContainer'

import Styles from './Success.module.scss'

const Success = () => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const userRegister = useSelector((state) => state.userRegister)
  const { userInfo: newUserInfo } = userRegister

  const renderSizeThirt = () => {
    if (newUserInfo && newUserInfo.tShirtSize) {
      return <span>{newUserInfo.tShirtSize}</span>
    } else if (userInfo && userInfo.tShirtSize) {
      return <span>{userInfo.tShirtSize}</span>
    }
  }

  return (
    <div className={Styles.success}>
      <div className={Styles.left}>
        <div className={Styles.logo}>
          <Link to='/'>
            <Logo isBtwb />
          </Link>
        </div>
        <h2>You're All Set!</h2>
        <div className={Styles.grace}>
          <h1>I’M IN A CHALLENGE</h1>
          <h2>AND YOU?</h2>
          <div className={Styles.labels}>
            <div className={Styles.label}>grace</div>
            <div className={Styles['label-icon']}></div>
          </div>
        </div>
        <Button variant='primary' className={Styles.share} type='button' block>
          Share to Social Media
        </Button>
        <div className={Styles.source}>
          <Button variant='dark' type='button' className={Styles.app}>
            <div className={Styles.icon}></div>
            <div className={Styles.subtitle}></div>
            <div className={Styles.title}></div>
          </Button>
          <Button variant='dark' type='button' className={Styles.google}>
            <div className={Styles.icon}></div>
            <div className={Styles.subtitle}></div>
            <div className={Styles.title}></div>
          </Button>
        </div>
      </div>

      <div className={Styles.right}>
        <SignContainer className={Styles.box}>
          <h2>You're All Set!</h2>

          {newUserInfo && (
            <div className={Styles.notification}>
              <p>
                Your Unlimited Free Trial is now active. You have 30 days to try
                us out at your gym with as many of your members as you’d like.
              </p>
              <span>End Date: 06/26/2021</span>
            </div>
          )}

          <div className={[Styles.separator, Styles.hidephone].join(' ')} />

          <h3>Dates</h3>
          <p className={Styles.info}>11 Jun, 2021 – 17 Jun, 2021</p>

          <h3>Posting Deadline</h3>
          <p className={Styles.info}>
            The deadline to post your result to the btwb app is
            <br />
            [Mon. June 15 @ 8pm EDT]
          </p>

          {((newUserInfo && newUserInfo.tShirtSize) ||
            (userInfo && userInfo.tShirtSize)) && (
            <>
              <div className={Styles.separator} />

              <div className={Styles.tshirt}>
                <div className={Styles.iconBox}>
                  <div className={Styles.icon}></div>
                </div>
                <div className={Styles.size}>
                  <h3>t-shirt size</h3>
                  {renderSizeThirt()}
                </div>
              </div>

              <div className={Styles.notification}>
                <p>
                  You must log your score to the btwb app in order to receive
                  your shirt.
                </p>
              </div>
            </>
          )}

          <h3>Next steps</h3>
          <p className={Styles.info}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            integer sit tristique sem sapien, ultrices senectus faucibus nibh.
          </p>

          <div className={Styles.source}>
            <Button variant='dark' type='button' className={Styles.app}>
              <div className={Styles.icon}></div>
              <div className={Styles.subtitle}></div>
              <div className={Styles.title}></div>
            </Button>
            <Button variant='dark' type='button' className={Styles.google}>
              <div className={Styles.icon}></div>
              <div className={Styles.subtitle}></div>
              <div className={Styles.title}></div>
            </Button>
          </div>
        </SignContainer>
      </div>
    </div>
  )
}

export default Success
