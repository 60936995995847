import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import {
  CHALLENGE_DIRECTORY
} from './constants';

import ScrollToTop from './ScrollToTop'

import Header from './components/Header'
import Footer from './components/Footer'
import Drawer from './components/Drawer'

import HomeScreen from './screens/HomeScreen'
import AboutScreen from './screens/AboutScreen'
import WorkoutsScreen from './screens/WorkoutsScreen'
import FaqScreen from './screens/FaqScreen'
import DivisionsScreen from './screens/DivisionsScreen'
import EarnedShirtScreen from './screens/EarnedShirtScreen'
import SponsorsScreen from './screens/SponsorsScreen'
import SignIn from './screens/SignIn'
import SignUp from './screens/SignUp'
import SelectDivision from './screens/SelectDivision'
import SelectTshirt from './screens/SelectTshirt'
import Success from './screens/Success'
import LeaderboardScreen from './screens/LeaderboardScreen';

import Styles from './App.module.scss'

const App = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)

  const openDrawerHandler = () => setIsOpenDrawer(true)
  const closeDrawerHandler = () => setIsOpenDrawer(false)

  return (
    <>
      <Router>
        <Header onOpen={openDrawerHandler} />
        <Drawer isOpen={isOpenDrawer} onClose={closeDrawerHandler} />
        <main className={Styles.main}>
          <ScrollToTop />
          <Switch>
            <Route path={`/${CHALLENGE_DIRECTORY}/about`} component={AboutScreen} />
            <Route path={`/${CHALLENGE_DIRECTORY}/workouts`} component={WorkoutsScreen} />
            <Route path={`/${CHALLENGE_DIRECTORY}/faq`} component={FaqScreen} />
            <Route path={`/${CHALLENGE_DIRECTORY}/divisions`} component={DivisionsScreen} />
            <Route path={`/${CHALLENGE_DIRECTORY}/earnedshirt`} component={EarnedShirtScreen} />
            <Route path={`/${CHALLENGE_DIRECTORY}/sponsors`} component={SponsorsScreen} />
            <Route path={`/${CHALLENGE_DIRECTORY}/leaderboard`} component={LeaderboardScreen} exact />
            <Route path={`/${CHALLENGE_DIRECTORY}/login`} component={SignIn} />
            <Route path={`/${CHALLENGE_DIRECTORY}/register`} component={SignUp} exact />
            <Route path={`/${CHALLENGE_DIRECTORY}/register/divisions`} component={SelectDivision} />
            <Route path={`/${CHALLENGE_DIRECTORY}/register/earnedshirt`} component={SelectTshirt} />
            <Route path={`/${CHALLENGE_DIRECTORY}/success`} component={Success} />
            <Route path={`/${CHALLENGE_DIRECTORY}/leaderboards`} component={LeaderboardScreen} />
            <Route path={`/${CHALLENGE_DIRECTORY}/`} component={HomeScreen} exact />
            <Redirect to={`/${CHALLENGE_DIRECTORY}/`} />
          </Switch>
        </main>
        <Footer />
      </Router>
    </>
  )
}

export default App
