import React from 'react';

import Styles from './Leaderboard.module.scss';

const Pagination = ({ page, setPage, count }) => {

  const firstPage = () => page < 1 ? Styles.off : '';
  const lastPage = () => count < 20 ? Styles.off : '';

  const goDirection = (direction) => {
    if(count < 20 && direction > 0) return;
    if(page < 1 && direction < 0) return;
    return setPage(page + direction);
  } 

  return(
    <div className={Styles.pagination}>
      <div
        onClick={() => goDirection(-1)}
        className={`${Styles.arrow} ${Styles.left} ${firstPage()}`}>

      </div>
      <div className={Styles.number}>
        {page + 1}
      </div>
      <div
        onClick={() => goDirection(1)}
        className={`${Styles.arrow} ${Styles.right} ${lastPage()}`}>
        
      </div>
    </div>
  )
};

export default Pagination;