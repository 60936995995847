import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

import Styles from './SponsorsScreen.module.scss'

const SponsorsScreen = () => {
  return (
    <Container fluid>
      <Row>
        <Col className={Styles.sponsors}>
          <h2>Sponsors</h2>
          <p>
            A list of sponsors helping put this event together.
          </p>
          <div className={Styles.logos}>
            <div className={Styles.row}>
              <a href="https://www.romwod.com" target="_blank" rel="noopener noreferrer"><div className={Styles.ufc}></div></a>
              <a href="https://www.rxsmartgear.com" target="_blank" rel="noopener noreferrer"><div className={[Styles.golds].join(' ')}></div></a>
            </div>
            <div className={Styles.row}>
              <a href="https://yeti.com" target="_blank" rel="noopener noreferrer"><div className={[Styles.crunch].join(' ')}></div></a>
              <a href="https://www.1stphorm.com" target="_blank" rel="noopener noreferrer"><div className={Styles.world}></div></a>
              <a href="https://www.nobullproject.com" target="_blank" rel="noopener noreferrer"><div className={[Styles.cc].join(' ')}></div></a>
            </div>

            {/*<div className={Styles.row}>
              <div className={Styles.ufc}></div>
              <div className={Styles.world}></div>
              <div className={Styles.crunch}></div>
              <div className={[Styles.cc, Styles.hide].join(' ')}></div>
              <div className={[Styles.golds, Styles.hide].join(' ')}></div>
            </div>*/}

            {/*<div className={Styles.row}>
              <div className={Styles.world}></div>
              <div className={Styles.ufc}></div>
              <div className={Styles.golds}></div>
              <div className={[Styles.crunch, Styles.hide].join(' ')}></div>
              <div className={[Styles.cc, Styles.hide].join(' ')}></div>
            </div>*/}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default SponsorsScreen
