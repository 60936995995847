import React from 'react'
// import { useHistory } from 'react-router-dom'

import { Container, Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'

import SmallLogo from '../../components/SmallLogo'
import ActiveStatus from '../../components/ActiveStatus';

import Styles from './AboutScreen.module.scss'

const AboutScreen = () => {
  // let history = useHistory()

  const signHandler = () => {
    window.location.href = "https://beyondthewhiteboard.com/earned/challenges/3"
  }

  const isClosed = () => {
    return moment('2021-12-21 23:59:59-07:00').isBefore(moment());

  }

  return (
    <Container fluid className={Styles.gradient}>
      <Row>
        <Col>
          <div className={Styles.about}>
            <Row>
              <Col md={6} className={Styles.left}>
                <h2>About The Masters Throwdown</h2>
                <p>
                  This CF Linchpin challenge is a global community event for athletes to showcase their hard work and to earn a unique t-shirt reflective of their performance. This idea was born from a desire to celebrate the efforts of everyday athletes from all around the world. Gyms and garages in every country are filled with real people with busy, hectic lives, pushing themselves every day to get better. 
                </p>
                <p>
                  Every person is at a different stage of their fitness journey, none more important than the other, which is why we’ve established 4 different levels for each Earned Series challenge. Participants' workout results will fall into one of the 4 levels.
                </p>
                <p>
                <b>Level 1:</b> Completing the scaled version of the workout.<br />
                <b>Level 2:</b> Anyone who can complete the workout prescribed, regardless of time.<br />
                <b>Level 3:</b> Requires participants to complete the workout Rx’d within a certain time cap that is challenging but realistic for many people.<br />
                <b>Level 4:</b> Participants must complete the workout Rx’d in a very competitive time cap that would place them around the top 10% in the world.
                </p>
                <p>
                  Each of these levels should be celebrated, and that’s exactly what we’re going to do. 
                  Keep in mind, performing a benchmark workout as Rx’d is a huge accomplishment in and of itself. 
                  Doing so places individuals in the top 10% of the ENTIRE WORLD population when it comes to fitness.
                </p>
                <p>
                  Participating in an Earned Series challenge is simple. 
                  Register for the challenge. 
                  Film yourself performing the workout. 
                  Log your result to btwb, and attach the video. 
                  ALL challenge results will be judged and verified, and will determine the Earned Shirt participants receive.
                </p>
                <p>
                  So why film the workout? 
                  First, filming your workout allows us to validate your effort. 
                  This makes it possible for us to provide a trustworthy, and fully verified leaderboard (the first of its kind). 
                  Also, we want participants to own their score, take pride in their results, and ultimately wear their unique Earned Shirt proudly. 
                  While there is a leaderboard and comparing scores is part of the fun, ultimately the Earned Series is a “You vs You” challenge commemorating an important milestone in your Fitness Journey.
                </p>
                <p>
                  Finally, performing a Hero workout would not be complete without giving back in some way. For the "DT" Challenge, we're happy to be contributing to The Gary Sinise Foundation. The Gary Sinise Foundation was established under the philanthropic direction of Gary Sinise, an actor and forty-year advocate for the nation's military and defenders. The Foundation's outreach supported those who sacrifice to defend the United States: active duty, veterans, first responders, and their families.
                </p>
                <p>
                  btwb is proud to be a part of your Fitness Journey. Now go earn it!
                </p>
              </Col>
              <Col md={6} className={Styles.right}>
                <div className={Styles.content}>
                  <h2>Fran</h2>
                  <p>December 17, 2021 at 9am PST through December 21, 2021 at 5pm PST</p>
                  <ActiveStatus />


                  {!isClosed() &&
                    <div className={Styles.action}>
                      <Button
                        variant='primary'
                        className={Styles.btn}
                        onClick={signHandler}
                      >
                        Sign up
                      </Button>

                    </div>
                  }

                  <div className={Styles.logos}>
                    <SmallLogo
                      topTitle='charity'
                      className={Styles.charity}
                      imageName="charity"
                    />
                    <p>Serving honor and need</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default AboutScreen
