import React from 'react'

import Card from '../../components/UI/Card'

import Styles from './Question.module.scss'

const Question = ({ question, onToggle }) => {
  const { id, sectionTitle, questions, open } = question

  const onOpenHandler = () => {
    onToggle(id)
  }

  return (
    <Card className={[Styles.question, open ? Styles.active : ''].join(' ')}>
      <button
        type='button'
        className={Styles.chevron}
        onClick={onOpenHandler}
      ></button>
      <div className={Styles.title} onClick={onOpenHandler}>
        {sectionTitle}
      </div>
      <div className={Styles.answer}>{questions.map((question, idx) =>
        <div className={Styles.inner} key={idx}>
          <h5 className={Styles.subtitle}>{question.title}</h5>
          <h4 dangerouslySetInnerHTML={{__html: question.answer}}></h4>
        </div>
      )}</div>
    </Card>
  )
}

export default Question
