import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import EventBlock from './EventBlock';

import Styles from './Leaderboard.module.scss';

import CountryConverter from './CountryConverter';

const handleScoreString = (sc) => {
  // target number is which scorecard to look at, variable on mobile (but not desktop)
  // const targetNumber = index - this.baseColumnCount + (this.activeOptions ? this.activeOptions : 0)
  let target = sc

  if (!target) return '-'
  let scoreVal = target.score_value || target.value
  let scoreUnit = target.score_unit || target.unit
  if (!scoreUnit || !scoreVal) return '-'

  if (scoreUnit === 'kg') {
    scoreVal = Math.round((scoreVal * 2.204) * 10) / 10
    scoreUnit = 'lbs'
  }

  let computedString = `${scoreVal} ${scoreUnit}`
  const handleDecimal = (sec) => {
    if (parseFloat(sec)) {
      return (Math.round(sec * 10)) / 10
    }

    return sec
  }

  const buildTime = (seconds) => {
    // const seconds = -10
    let mins = 0
    if (seconds < -1) {
      mins = `${Math.ceil(seconds / 60)}`
    } else if (seconds < 0) {
      mins = `-${Math.ceil(seconds / 60)}`
    } else {
      mins = Math.floor(seconds / 60)
    }
    const secsNumber = handleDecimal(Math.abs(seconds) % 60)
    const secs = Math.abs(seconds) % 60 < 10 ? `0${secsNumber}` : secsNumber

    return `${mins}:${secs}`
  }

  if (scoreUnit === 'seconds' || scoreUnit === 's' || scoreUnit === 'time') {
    computedString = buildTime(scoreVal)
  } else if (scoreUnit === 'msec' || scoreUnit === 'msecs') {
    computedString = buildTime(scoreVal / 1000)
  }

  return computedString
}

const LeaderboardRow = ({ athlete, events, activeEventIdx, amountOfEvents }) => {

  const [expanded, setExpanded] = useState(false);

  const checkIfExpanded = () => !expanded ? Styles.hideMobile : Styles.showEvents;
  const checkIfExpandedTwo = () => !expanded ? Styles.nil : Styles.flip;
  const checkIfActiveEvent = (i) => i < activeEventIdx || i > (activeEventIdx + (amountOfEvents - 1)) ? Styles.hideDesktop : '';

  return(
    <div className={Styles.leaderboardRow}>
      <div className={Styles.mobileFlex}>
        <Col className={[Styles.cell, Styles.rank]}>
          {athlete.e0_rank}
        </Col>
        <Col className={[Styles.cell, Styles.athleteColumn]}>
          <div className={Styles.athleteBlock}>
            <div>
              {athlete.image_url &&
                <img 
                  src={athlete.image_url}
                  alt="athlete" 
                />
              }
            </div>
            <div className={Styles.rightBlock}>
              <div className={Styles.athleteName}>
                <p>{athlete.full_name}</p>
                {events.length === 1 &&
                  <div className={Styles.singleScore}>{handleScoreString(athlete[`e1_sc`])}</div>
                }
              </div>
              <div>
                {athlete.nationality &&
                  <img 
                    className={Styles.nationality}
                    src={`https://s3.amazonaws.com/dev.btwb.co/flags/${CountryConverter[athlete.nationality.toLowerCase()] ? CountryConverter[athlete.nationality.toLowerCase()] : athlete.nationality}.png`}
                    alt="natl"
                  /> 
                }
              </div>
              {events.length > 1 &&
                <div 
                  onClick={() => setExpanded(!expanded)}
                  className={[Styles.eventsDropdownSelector, checkIfExpandedTwo()].join(' ')}>
                </div>
              }
            </div>
          </div>
        </Col>
      </div>
      {(events || []).map((e, i) => {

        return(<Col 
          className={[
            Styles.cell, 
            Styles.eventBlock, 
            (events.length === 1 ? Styles.hideMobile : ''), 
            checkIfExpanded(),
            checkIfActiveEvent(i)
          ]}
          key={i}>
          <EventBlock
            rank={athlete[`e${i + 1}_rank`]}
            event={events[i]}
            scorecard={athlete[`e${i + 1}_sc`]}
            handleScoreString={handleScoreString}
          />
        </Col>)
      })}
    </div>
  )
};

export default LeaderboardRow;