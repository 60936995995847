import {
  WORKOUTS_LIST_REQUEST,
  WORKOUTS_LIST_SUCCESS,
  WORKOUTS_LIST_FAIL,
} from '../constants/workoutsConstants'

const divisions = [
  {
    id: '35',
    title: 'Weighted',
    description: 'Lotta burpees, lotta running',
    workouts: [
      {
        id: 'w4',
        title: "Linchpin Test 13 - Weighted",
        workoutRows: [
          "For time:",
          "400 m Run",
          "20 Burpees",
          "400 m Run",
          "19 Burpees",
          "400 m Run",
          "18 Burpees",
          "400 m Run",
          "17 Burpees",
          "400 m Run",
          "16 Burpees",
          "400 m Run",
          "15 Burpees",
          "etc…",
          "…until",
          "400 m Run",
          "1 Burpee",
          "",
          "Men: 20-lb weight vest",
          "Women: 14-lb weight vest"
        ],
        description: 'Once the clock starts the athlete runs 400m & then performs 20 burpees. The athlete then runs 400m & performs 19 burpees. The athlete then runs 400m & performs 18 burpees. The athlete then runs 400m & performs 17 burpees. The athlete continues to work in this manner with the burpees descending by 1 rep each time. Once the final burpee is finished the time stops.',
        equipment: [
          'A safe, unobstructed area to perform the workout',
          'Weight vest, 20/14 lbs'
        ],
        instructions: 'Do Linchpin Test 13.',
        movementStandards: [{
         title: 'Double Unders:',
         instructions: [
           'This is the standard double-under in which the rope passes under the feet twice for each jump. The rope must spin forward for the rep to count. Only successful jumps are counted, not attempts. '
         ],
         gif: 'https://s3.amazonaws.com/media.btwb.co/Competitions/demos/double-under.gif'
        }, {
         title: 'Wall Balls:',
         instructions: [
           'The athlete begins standing tall in front of the barbell. Once the timer begins counting the athlete may reach down, grab the barbell & begin the workout. The workout ends when the athlete completes the 6th push jerk in the final round. The athlete’s score is their total time.',
         ],
         gif: 'https://s3.amazonaws.com/media.btwb.co/Competitions/demos/wall-ball.gif',
        }, {
         title: 'Bar Muscle-Ups:',
         instructions: [
           'For the bar muscle-up, you must begin with, or pass through, a hang below the bar with arms fully extended and the feet off the ground. Kipping the muscle-up is acceptable, but pullovers, rolls to support or glide kips are not permitted. The heels may not rise above the height of the bar during the kip.',
           'At the top, the elbows must be fully locked out while the athlete supports himself or herself above the bar with the shoulders over or in front of the bar. Athletes must pass through some portion of a dip to lockout over the bar.',
         ]
        }],
        pdfLinks: [{
          title: 'Official Rules Rules PDF',
          link: 'https://s3.amazonaws.com/media.btwb.co/Competitions/linchpin/2024/linchpin_test_13/rules.pdf'
        }],
        movementGifs: [
          'https://media3.giphy.com/media/xTiTntOfEb7Pqw5CLK/giphy.gif?cid=ecf05e47r8at0lb4nyoja6xrlvanspnfg649vteilz6w9o57&rid=giphy.gif'
        ],
        movementVideo: 'hYqCcFNVWZ8'
      }
    ]
  },
  {
    id: '35',
    title: 'Unweighted',
    description: 'Lotta burpees, lotta running',
    workouts: [
      {
        id: 'w4',
        title: "Linchpin Test 13 - Unweighted",
        workoutRows: [
          "For time:",
          "400 m Run",
          "20 Burpees",
          "400 m Run",
          "19 Burpees",
          "400 m Run",
          "18 Burpees",
          "400 m Run",
          "17 Burpees",
          "400 m Run",
          "16 Burpees",
          "400 m Run",
          "15 Burpees",
          "etc…",
          "…until",
          "400 m Run",
          "1 Burpee"
        ],
        description: 'Once the clock starts the athlete runs 400m & then performs 20 burpees. The athlete then runs 400m & performs 19 burpees. The athlete then runs 400m & performs 18 burpees. The athlete then runs 400m & performs 17 burpees. The athlete continues to work in this manner with the burpees descending by 1 rep each time. Once the final burpee is finished the time stops.',
        equipment: [
          'A safe, unobstructed area to perform the workout'
        ],
        instructions: 'Do Linchpin Test 13.',
        movementStandards: [{
         title: 'Double Unders:',
         instructions: [
           'This is the standard double-under in which the rope passes under the feet twice for each jump. The rope must spin forward for the rep to count. Only successful jumps are counted, not attempts. '
         ],
         gif: 'https://s3.amazonaws.com/media.btwb.co/Competitions/demos/double-under.gif'
        }, {
         title: 'Wall Balls:',
         instructions: [
           'The athlete begins standing tall in front of the barbell. Once the timer begins counting the athlete may reach down, grab the barbell & begin the workout. The workout ends when the athlete completes the 6th push jerk in the final round. The athlete’s score is their total time.',
         ],
         gif: 'https://s3.amazonaws.com/media.btwb.co/Competitions/demos/wall-ball.gif',
        }, {
         title: 'Bar Muscle-Ups:',
         instructions: [
           'For the bar muscle-up, you must begin with, or pass through, a hang below the bar with arms fully extended and the feet off the ground. Kipping the muscle-up is acceptable, but pullovers, rolls to support or glide kips are not permitted. The heels may not rise above the height of the bar during the kip.',
           'At the top, the elbows must be fully locked out while the athlete supports himself or herself above the bar with the shoulders over or in front of the bar. Athletes must pass through some portion of a dip to lockout over the bar.',
         ]
        }],
        pdfLinks: [{
          title: 'Official Rules Rules PDF',
          link: 'https://s3.amazonaws.com/media.btwb.co/Competitions/linchpin/2024/linchpin_test_13/rules.pdf'
        }],
        movementGifs: [
          'https://media3.giphy.com/media/xTiTntOfEb7Pqw5CLK/giphy.gif?cid=ecf05e47r8at0lb4nyoja6xrlvanspnfg649vteilz6w9o57&rid=giphy.gif'
        ],
        movementVideo: 'hYqCcFNVWZ8'
      }
    ]
  },
  {
    id: '35',
    title: 'Air Bike',
    description: 'Lotta burpees, lotta biking',
    workouts: [
      {
        id: 'w4',
        title: "Linchpin Test 13 - Air Bike",
        workoutRows: [
          "For time:",
          "1200 m Air Bike",
          "20 Burpees",
          "1200 m Air Bike",
          "19 Burpees",
          "1200 m Air Bike",
          "18 Burpees",
          "1200 m Air Bike",
          "17 Burpees",
          "1200 m Air Bike",
          "16 Burpees",
          "1200 m Air Bike",
          "15 Burpees",
          "etc…",
          "…until",
          "1200 m Air Bike",
          "1 Burpee"
        ],
        description: 'Once the clock starts the athlete performs 1200m on the air bike & then performs 20 burpees. The athlete then does 1200m on the air bike & performs 19 burpees. The athlete then does 1200m on the air bike & performs 18 burpees. The athlete then does 1200m on the air bike & performs 17 burpees. The athlete continues to work in this manner with the burpees descending by 1 rep each time. Once the final burpee is finished the time stops.',
        equipment: [
          'A safe, unobstructed area to perform the workout'
        ],
        instructions: 'Do Linchpin Test 13.',
        movementStandards: [{
         title: 'Double Unders:',
         instructions: [
           'This is the standard double-under in which the rope passes under the feet twice for each jump. The rope must spin forward for the rep to count. Only successful jumps are counted, not attempts. '
         ],
         gif: 'https://s3.amazonaws.com/media.btwb.co/Competitions/demos/double-under.gif'
        }, {
         title: 'Wall Balls:',
         instructions: [
           'The athlete begins standing tall in front of the barbell. Once the timer begins counting the athlete may reach down, grab the barbell & begin the workout. The workout ends when the athlete completes the 6th push jerk in the final round. The athlete’s score is their total time.',
         ],
         gif: 'https://s3.amazonaws.com/media.btwb.co/Competitions/demos/wall-ball.gif',
        }, {
         title: 'Bar Muscle-Ups:',
         instructions: [
           'For the bar muscle-up, you must begin with, or pass through, a hang below the bar with arms fully extended and the feet off the ground. Kipping the muscle-up is acceptable, but pullovers, rolls to support or glide kips are not permitted. The heels may not rise above the height of the bar during the kip.',
           'At the top, the elbows must be fully locked out while the athlete supports himself or herself above the bar with the shoulders over or in front of the bar. Athletes must pass through some portion of a dip to lockout over the bar.',
         ]
        }],
        pdfLinks: [{
          title: 'Official Rules Rules PDF',
          link: 'https://s3.amazonaws.com/media.btwb.co/Competitions/linchpin/2024/linchpin_test_13/rules.pdf'
        }],
        movementGifs: [
          'https://media3.giphy.com/media/xTiTntOfEb7Pqw5CLK/giphy.gif?cid=ecf05e47r8at0lb4nyoja6xrlvanspnfg649vteilz6w9o57&rid=giphy.gif'
        ],
        movementVideo: 'hYqCcFNVWZ8'
      }
    ]
  },,
  {
    id: '35',
    title: 'Row',
    description: 'Lotta burpees, lotta rowing',
    workouts: [
      {
        id: 'w4',
        title: "Linchpin Test 13 - Row",
        workoutRows: [
          "For time:",
          "500 m Row",
          "20 Burpees",
          "500 m Row",
          "19 Burpees",
          "500 m Row",
          "18 Burpees",
          "500 m Row",
          "17 Burpees",
          "500 m Row",
          "16 Burpees",
          "500 m Row",
          "15 Burpees",
          "etc…",
          "…until",
          "500 m Row",
          "1 Burpee"
        ],
        description: 'Once the clock starts the athlete performs 1200m on the air bike & then performs 20 burpees. The athlete then does 1200m on the air bike & performs 19 burpees. The athlete then does 1200m on the air bike & performs 18 burpees. The athlete then does 1200m on the air bike & performs 17 burpees. The athlete continues to work in this manner with the burpees descending by 1 rep each time. Once the final burpee is finished the time stops.',
        equipment: [
          'A safe, unobstructed area to perform the workout'
        ],
        instructions: 'Do Linchpin Test 13.',
        movementStandards: [{
         title: 'Double Unders:',
         instructions: [
           'This is the standard double-under in which the rope passes under the feet twice for each jump. The rope must spin forward for the rep to count. Only successful jumps are counted, not attempts. '
         ],
         gif: 'https://s3.amazonaws.com/media.btwb.co/Competitions/demos/double-under.gif'
        }, {
         title: 'Wall Balls:',
         instructions: [
           'The athlete begins standing tall in front of the barbell. Once the timer begins counting the athlete may reach down, grab the barbell & begin the workout. The workout ends when the athlete completes the 6th push jerk in the final round. The athlete’s score is their total time.',
         ],
         gif: 'https://s3.amazonaws.com/media.btwb.co/Competitions/demos/wall-ball.gif',
        }, {
         title: 'Bar Muscle-Ups:',
         instructions: [
           'For the bar muscle-up, you must begin with, or pass through, a hang below the bar with arms fully extended and the feet off the ground. Kipping the muscle-up is acceptable, but pullovers, rolls to support or glide kips are not permitted. The heels may not rise above the height of the bar during the kip.',
           'At the top, the elbows must be fully locked out while the athlete supports himself or herself above the bar with the shoulders over or in front of the bar. Athletes must pass through some portion of a dip to lockout over the bar.',
         ]
        }],
        pdfLinks: [{
          title: 'Official Rules Rules PDF',
          link: 'https://s3.amazonaws.com/media.btwb.co/Competitions/linchpin/2024/linchpin_test_13/rules.pdf'
        }],
        movementGifs: [
          'https://media3.giphy.com/media/xTiTntOfEb7Pqw5CLK/giphy.gif?cid=ecf05e47r8at0lb4nyoja6xrlvanspnfg649vteilz6w9o57&rid=giphy.gif'
        ],
        movementVideo: 'hYqCcFNVWZ8'
      }
    ]
  }
]

export const listWorkouts = () => async (dispatch) => {
  try {
    dispatch({ type: WORKOUTS_LIST_REQUEST })

    // const { data } = await axios.get('/api/workouts')

    const data = divisions // temporary

    dispatch({ type: WORKOUTS_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: WORKOUTS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
