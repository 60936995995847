import { Alert } from 'react-bootstrap'

const Message = ({ variant, children, className }) => {
  return (
    <Alert
      variant={variant}
      className={className}
      style={{ margin: '0 15px', flexGrow: '1' }}
    >
      {children}
    </Alert>
  )
}

Message.defaultProps = {
  variant: 'info',
}

export default Message
