import React from 'react'

import { Container, Row, Col, Image } from 'react-bootstrap'

import Styles from './EarnedShirtScreen.module.scss'

const EarnedShirtScreen = () => {
  return (
    <Container fluid className={Styles.gradient}>
      <Row>
        <Col>
          <div className={Styles.shirt}>
            <Row>
              <Col
                md={{ span: 12, order: 1 }}
                xs={{ span: 12, order: 2 }}
                className={Styles.left}
              >
                <h2>Earned Series</h2>
                <p>
                  In the Earned Series ALL participants will submit a video of their effort. Leaderboard placing, t-shirt, and challenge badge are all tied to official video verification. A participant will NOT appear on a leaderboard, or receive an official challenge badge and t-shirt without a video. Participants will receive challenge badges and t-shirts (if purchased) that match the level of their effort. Earned Series challenges contain 4 different levels, each commemorating a specific milestone in an athlete’s fitness journey. Follow the standards and guidelines outlined below to ensure proper video verification so that you can truly earn it!
                </p>

                <h3>Equipment</h3>
                <p>
                  Participants must abide by the equipment requirements laid out below.
                </p>
                <ul className={Styles.equipments}>
                  <li>45/35-lb. Barbell (20 kg./15 kg.)</li>
                  <li>
                    Standard size bumper plates or weight plates of correct weight & clearly marked
                  </li>
                  <li>Collars</li>
                  <li>Visible clock, timer in frame, phone timers, apps & stopwatches are acceptable as long as they are in frame and clearly visible the entire time</li>
                  <li>A safe, unobstructed area to perform the workout</li>
                  <li>Chalk, weight belts, knee/elbow sleeves, wrist wraps and any style of shoe are permitted, but not required</li>
                </ul>

                <div className={Styles.standards}>
                  <h3>Video Submission Guidelines</h3>
                  <p>
                    The barbell & plates should be filmed in a way that allows the loads to be clearly identified. Videos need to be uncut & unedited in order to accurately display the performance. Videos shot with a fisheye lens or similar lens may be rejected, we recommend not using such lenses. The clock or timer must be visible during the entire workout. Phone timers, apps & stopwatches are acceptable as long as they are in frame and clearly visible the entire time. Ensure the athlete has ample space to safely perform all aspects of the workout & all potential obstructions have been removed. The video should be filmed in such a way as to clearly capture the entire range of motion of all movements required for the workout and allow for easy verification of the movement standards.
                  </p>
                  <p>At the start of the video the athlete should be in frame, state their name, the challenge they are performing and which workout variation they choose to do (Rx’d, Scaled, etc.). Next, the barbell & plates should be filmed in a way that allows the loads to be clearly identified. Keep in mind everything needs to be filmed in one continuous, unedited video.
                  </p>
                  <p>Workout results & videos need to be logged by Tuesday, December 21, 2021 at 5pm PST. Be sure to allow sufficient time to submit your score and attach your video.
                  </p>
                </div>

                <div className={Styles.video}>
                  <iframe 
                    className={Styles.content}
                    src="https://www.youtube.com/embed/w4PkZBkuhLU" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
                  </iframe>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default EarnedShirtScreen
