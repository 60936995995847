import React from 'react';
import { Col } from 'react-bootstrap';

import Styles from './Leaderboard.module.scss';

const LeaderboardHeader = ({ events, selectedEvent, setSelectedEvent, activeEventIdx, amountOfEvents }) => {

  const activeCheck = e => e.id === selectedEvent ? Styles.active : '';
  const checkIfActiveEvent = i => i < activeEventIdx || i > (activeEventIdx + (amountOfEvents - 1)) ? Styles.hideDesktop : '';

  return(<div className={Styles.leaderboardHeader}>
    <Col 
      className={[Styles.cell, Styles.rank]}
      onClick={() => setSelectedEvent(0)}>
      Overall Rank
    </Col>
    <Col className={[Styles.cell, Styles.athlete]}>
      Athlete
    </Col>
    {(events || []).map((e, i) => {
      return (<Col
        className={[Styles.cell, Styles.event, activeCheck(e), checkIfActiveEvent(i)]}
        key={i}
        onClick={() => setSelectedEvent(e.id)}>
        Event {i + 1}
      </Col>)
    })}
  </div>)
};

export default LeaderboardHeader;