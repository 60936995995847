import Styles from './Card.module.scss'

const Card = ({ children, className }) => {
  return (
    <div className={[Styles.card, className ? className : ''].join(' ')}>
      {children}
    </div>
  )
}

export default Card
