import {
  SpraypaintBase,
} from 'spraypaint'

// let baseUrl = "http://192.168.1.152:3000";
const baseUrl = 'https://phelan.btwb.com'

const ApplicationRecord = SpraypaintBase.extend({
  static: {
    baseUrl,
    apiNamespace: '/',
  },
})

export default ApplicationRecord
