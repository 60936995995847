import React, { useState, useEffect } from 'react';

import Styles from './Leaderboard.module.scss';

function EventChanger({ activeEventIdx, setActiveEventIdx, amountOfEvents, totalEvents }) {

  const [top, setTop] = useState(0);

  const handleScroll = () => {
    const scrollHeight = window.scrollY || window.pageYOffset;
    const windowHeight = (window.innerHeight / 2);
    if(!document.getElementById('leaderboard')) return null;
    const leaderboardOffset = document.getElementById('leaderboard').offsetTop;

    setTop(windowHeight + scrollHeight - leaderboardOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll, false);
  }, []);

  return(
    <div className={[Styles.eventChanger, Styles.hideMobile].join(' ')}>
      {activeEventIdx >0 &&
        <div 
          onClick={() => setActiveEventIdx(activeEventIdx -1)}
          style={{transform: `translateY(${top}px)`}}
          className={[Styles.arrow, Styles.left].join(' ')}>
        </div>
      }
      {(activeEventIdx + amountOfEvents) < totalEvents &&
        <div 
          onClick={() => setActiveEventIdx(activeEventIdx +1)}
          style={{transform: `translateY(${top}px)`}}
          className={[Styles.arrow, Styles.right].join(' ')}>
        </div> 
      }
    </div>
  );
};

export default EventChanger;