import React from 'react'

import Styles from './Division.module.scss'

const Division = ({ title, content }) => {
  return (
    <div className={Styles.division}>
      <div className={Styles.title}>{title}</div>
      <p>{content}</p>
    </div>
  )
}

export default Division
