import {
  DIVISIONS_LIST_REQUEST,
  DIVISIONS_LIST_SUCCESS,
  DIVISIONS_LIST_FAIL,
} from '../constants/divisionsConstants'

export const divisionsListReducer = (state = { divisions: [] }, action) => {
  switch (action.type) {
    case DIVISIONS_LIST_REQUEST:
      return { loading: true, divisions: [] }
    case DIVISIONS_LIST_SUCCESS:
      return { loading: false, divisions: action.payload }
    case DIVISIONS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
