import {
  attr,
} from 'spraypaint'

import ApplicationRecord from './ApplicationRecord'

const Leaderboard = ApplicationRecord.extend({
  static: {
    jsonapiType: 'leaderboards',
  },
  attrs: {
    ageGroup: attr(),
    createdAt: attr(),
    family: attr(),
    season: attr(),
    series: attr(),
    gender: attr(),
    organizationId: attr(),
    displayName: attr()
  },
})

export default Leaderboard