import React from 'react';
import urlParser from 'js-video-url-parser';

import Styles from './Leaderboard.module.scss';

const EventBlock = ({ scorecard, event, rank, handleScoreString }) => {

  if(!scorecard) return null;

  let imageUri;
  if (scorecard.video_url) {
    let parser = urlParser.parse(scorecard.video_url);

    if (parser && parser.provider === 'youtube') {
      imageUri = urlParser.create({
        videoInfo: parser,
        params: {
          imageQuality: '0',
        },
        format: 'shortImage'
      });
    }
  }

  const ordinal_suffix_of = (i) => {
    let j = i % 10;
    let k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  return(
    <div className={Styles.eventBlockDetail}>
      <div className={Styles.mobileFlex}>
        <p className={Styles.workoutTitle}>{event.display_name}</p>
        <div className={Styles.workoutResults}>
          {ordinal_suffix_of(rank)} ({ handleScoreString(scorecard) })
        </div>
      </div>
      <div>
        {imageUri &&
          <a href={scorecard.video_url.replace('Bennett DT Challenge BTWB ', '')}>
            <div 
              className={Styles.video}
              style={{ backgroundImage: `url(${imageUri})` }}>
              <div className={Styles.videoIcon}>

              </div>
            </div>
          </a>
        }
      </div>
    </div>
  )
};

export default EventBlock;