import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Container, Row, Col } from 'react-bootstrap'

import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Division from './Division'

import { listDivisions } from '../../actions/divisionsAction'

import Styles from './DivisionsScreen.module.scss'

const DivisionsScreen = () => {
  const dispatch = useDispatch()
  const divisionsList = useSelector((state) => state.divisionsList)
  const { loading, error, divisions } = divisionsList

  useEffect(() => {
    dispatch(listDivisions())
  }, [dispatch])

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className={Styles.divisions}>
            <Row>
              <Col md={6} className={Styles.left}>
                <h2>Divisions</h2>
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message variant='danger'>{error}</Message>
                ) : (
                  <>
                    {divisions.map(({ id, title, content }) => (
                      <Division key={id} title={title} content={content} />
                    ))}
                    {divisions.length === 0 && <h3>Divisions not found</h3>}
                  </>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default DivisionsScreen
