import React, { useState } from 'react';
import LeaderboardHeader from './LeaderboardHeader';
import LeaderboardRow from './LeaderboardRow';
import Pagination from './Pagination';
import EventChanger from './EventChanger';

import Styles from './Leaderboard.module.scss';

const Leaderboard = ({ standings, events, selectedEvent, setSelectedEvent, page, setPage }) => {

  const [activeEventIdx, setActiveEventIdx] = useState(0);
  const [amountOfEvents] = useState(2); 

  return(<div>
    <div className={Styles.leaderboard} id="leaderboard">
      <LeaderboardHeader
        events={events}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        activeEventIdx={activeEventIdx}
        amountOfEvents={amountOfEvents}
      />

      {(standings || []).map((s, i) =>
        <LeaderboardRow
          key={i}
          athlete={s}
          events={events}
          activeEventIdx={activeEventIdx}
          amountOfEvents={amountOfEvents}
        />
      )}
      {events.length > 1 &&
        <EventChanger
          activeEventIdx={activeEventIdx}
          setActiveEventIdx={setActiveEventIdx}
          amountOfEvents={amountOfEvents}
          totalEvents={events.length}
        />
      }
    </div>
    <Pagination
      page={page}
      setPage={setPage}
      count={standings.length}
    />
  </div>);
};

export default Leaderboard;