import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Container, Row, Col } from 'react-bootstrap'

import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Workout from './Workout'

import { listWorkouts } from '../../actions/workoutsAction'

import Styles from './WorkoutsScreen.module.scss'

const WorkoutsScreen = () => {
  const dispatch = useDispatch()
  const workoutsList = useSelector((state) => state.workoutsList)
  const { loading, error, workouts } = workoutsList
  const [selected, setSelected] = useState('0')

  useEffect(() => {
    dispatch(listWorkouts())
  }, [dispatch])

  useEffect(() => {

  }, [selected])

  if(loading || error) return null;
  if(!workouts[parseInt(selected)]) return null;
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className={Styles.workouts}>
            <Col md={6} className={Styles.workoutsHeader}>
              <div>
                <h2>{workouts[parseInt(selected)].title}</h2>
              </div>
              <div className={Styles.divisionSwitcher}>
                <h4>Divisions:</h4>
                <select className={Styles.dropdown} onChange={(e) => setSelected(e.target.value)}>
                  {workouts.map((workout, index) => (
                    <option key={index} value={index}>{workout.title}</option>
                  ))}
                </select>
              </div>
            </Col>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <>
                {workouts[parseInt(selected)].workouts.map((workout) => (
                  <Workout key={workout.title} workout={workout} />
                ))}
                {workouts.length === 0 && <h3>Workouts not found</h3>}
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default WorkoutsScreen
