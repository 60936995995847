import React from 'react'

import Question from './Question'

const Questions = ({ questions, onToggle }) => {
  return (
    <>
      {questions.map((question) => (
        <Question key={question.id} question={question} onToggle={onToggle} />
      ))}
      {questions.length === 0 && <h3>Not have questions yet</h3>}
    </>
  )
}

export default Questions
